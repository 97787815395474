/* STYLING FOR MOBILE DEVICES */

@media (min-width: 0px) and (max-width: 480px) {
    .TemplateLayoutTop {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .HintButton {
        font-family: "Open Sans";
        color: #000 !important;
    }
    .TemplateLayoutRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 2%;
    }
    .FeedbackRow {
        margin-top: 1%;
        margin-bottom: -10%;
        text-align: center;
        justify-content: center;
    }
    .LandscapeLayoutRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 5%;
    }
    .PortraitStaggeredLayoutRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-bottom: 10%;
    }
    .TemplateLayoutCell {
        width: 50%;
        padding-bottom: 5px;
        border: none;
        background: none;
    }
    .fa-question-circle {
        color: #4040FF;
    }
    .container {
        display: flex;
        justify-content: center;
    }
    .item {
        padding-left: 10%;
        align-self: center;
    }
    /* changes the SVG images features */
    .CompoundImage {
        padding: 5%;
        border: solid black;
        border-width: 2px;
    }
    /* changes the svg images display features AFTER clicked and held down */
    .CompoundImage:active {
        background-color: #ffffff;
        position: relative;
        display: block;
        top: -10px;
        left: -95px;
        z-index: 99;
        transform: scale(2.5);
        transition: 300ms ease;
    }
    .PortraitStaggeredCompoundImage {
        width: 130px;
        height: 90px;
        border: solid black;
        border-width: 2px;
    }
    .LandscapeCompoundImage {
        width: 110px;
        height: 75px;
        border: solid black;
        border-width: 2px;
    }
    .QuestionCard {
        padding-top: 15px;
        padding-bottom: 10px;
        width: 85%;
        margin: auto;
        font-family: "Open Sans";
        font-weight: bold;
    }
    .card {
        border: none;
        font-size: 3vw;
    }
    .SubmitButton {
        margin-left: 10px;
        margin-right: 10px;
    }
    .PortraitStaggeredSubmitButton {
        width: 40%;
    }
    .LandscapeSubmitButton {
        width: 40%;
        margin-top: 5%;
    }
    .DropdownCategory {
        margin-top: 15%;
        text-align: center;
    }
    .GoButton {
        margin-top: 20%;
        text-align: center;
    }
    .CustomizedGoButton {
        background-color: #0CCA4A !important;
        border: solid #0CCA4A !important;
        width: 30%;
    }
    .SubmitButton {
        font-family: "Open Sans";
        margin-left: 10px;
        margin-right: 10px;
    }
    .SkipButton {
        font-family: "Open Sans";
        margin-left: 10px;
        margin-right: 10px;
    }
    .NextQuestionButton {
        width: 40%;
        margin-top: 3%;
        background-color: #4040FF;
        border: solid #4040FF;
        font-family: "Open Sans";
        font-size: 12px;
    }
    .ORankLogo {
        width: 70%;
        margin-top: 10%;
        margin-bottom: -15%;
        margin-left: 20%;
    }
    .CorrectSolutionCard {
        margin-left: auto;
        margin-right: auto;
        margin-top: -500px;
        z-index: 1;
        width: 90%;
        max-height: 500px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 15px;
        align-items: center;
        text-align: left;
    }
    .IncorrectSolutionCard {
        margin-left: auto;
        margin-right: auto;
        margin-top: -240px;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 90%;
        max-height: 500px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 15px;
    }
    .InvalidRankingCard {
        margin-left: auto;
        margin-right: auto;
        margin-top: -150px;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 90%;
        max-height: 500px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 15px;
        text-align: center;
    }
    .HintCard {
        margin-left: auto;
        margin-right: auto;
        margin-top: -470px;
        align-items: center;
        z-index: 1;
        width: 90%;
        max-height: 500px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 15px;
        text-align: left;
    }
    .HintCategory {
        margin-bottom: 15%;
    }
    .HintGlossaryDiv {
        padding: 20px;
    }
    .CyclohexaneHintImage {
        padding: 15px;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .fa-times {
        margin-left: 95%;
        font-size: 20px;
        color: red;
    }
    .fa-times:hover {
        cursor: pointer;
    }
    .fa-info-circle {
        margin-left: 2%;
        font-size: 15px;
        color: blue;
    }
    .fa-info-circle:hover {
        cursor: pointer;
    }
    .SolutionImage {
        width: 100%;
        height: 100px;
    }
    .form-label {
        font-family: "Open Sans";
        font-size: 17px;
        font-weight: bold;
    }
    .form-control {
        width: 60%;
        margin: auto;
        text-align-last: center;
    }
    .RankWidth {
        width: 60px;
    }
    option {
        font-family: "Open Sans";
        font-size: 15px;
    }
    .CustomizedDropdown {
        background-color: #4040FF !important;
        border: solid #4040FF !important;
        width: 50%;
    }
    .fa-arrow-circle-right {
        color: #0CCA4A;
        font-size: 55px;
        margin-top: -40px;
        margin-left: 65%;
    }
    .fa-home {
        font-size: 40px;
        padding-top: 7px;
    }
    .footer {
        background-color: white;
        color: black;
        font-size: 10px;
        padding-top: 5px;
        height: 25px;
        align-content: center;
    }
    .footer-bottom {
        background-color: #25274D;
        font-family: 'Open Sans';
        font-size: 10px;
    }
    .AuthorCard .card-title {
        font-family: 'Open Sans';
        font-weight: 900;
        font-size: 25px;
        text-align: center;
        margin-top: 5%;
        color: black;
    }
    .AuthorCard .card-body {
        font-family: 'Open Sans';
        font-size: 15px;
    }
    quick-link {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 600;
        color: black;
    }
    quick-link:hover {
        text-decoration: black underline;
    }
    hint-link {
        font-family: 'Open Sans';
        font-size: 20px;
        font-weight: 600;
        color: blue;
    }
    hint-link:hover {
        text-decoration: underline;
    }
    solution-text {
        font-size: 15px;
    }
    feedback-text {
        font-size: 12px;
    }
    h1 {
        margin-top: 35%;
        text-align: center;
        font-size: 60px;
        font-family: "Open Sans"
    }
    h2 {
        text-align: center;
        font-size: 20px;
        font-family: "Open Sans"
    }
    h3 {
        text-align: center;
        color: red;
        font-size: 30px;
        font-weight: 900;
        font-family: "Open Sans"
    }
    h5 {
        text-align: center;
        color: green;
        font-size: 30px;
        font-weight: 900;
        font-family: "Open Sans"
    }
    h6 {
        text-align: center;
        color: black;
        font-size: 11px;
        font-weight: bold;
        font-family: "Open Sans"
    }
    .SolutionActionBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .HomeButton {
        margin-left: 10px;
        margin-right: 10px;
    }
    .ImageGridContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}


/* STYLING FOR LAPTOP DEVICES */

@media (min-width: 480px) {
    .ORankLogo {
        width: 15%;
        margin-left: 45%;
        margin-right: 45%;
    }
    .DropdownCategory {
        text-align: center;
        width: 50%;
        margin: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .form-label {
        font-family: "Open Sans";
        font-size: 25px;
        font-weight: bold;
    }
    .form-control {
        width: 60%;
        margin: auto;
        text-align-last: center;
        font-size: 25px;
    }
    /* category's menu font-size */
    option {
        font-family: "Open Sans";
        font-size: 25px;
    }
    /* the green GO button's distance from margin */
    .GoButton {
        margin-top: 5%;
    }
    .fa-arrow-circle-right {
        color: #0CCA4A;
        font-size: 100px;
        margin-top: -40px;
        margin-left: 65%;
    }
    .fa-arrow-circle-right:hover {
        cursor: pointer;
    }
    /*Layout of question*/
    .TemplateLayoutTop {
        margin: auto;
        flex-direction: column;
        width: 100%;
    }
    .QuestionCard {
        width: 1000px;
        margin-left: -80%;
        margin-right: 10%;
        font-family: "Open Sans";
        font-size: 22px;
        font-weight: bold;
    }
    /*The font of the question*/
    .card {
        border: none;
        font-size: 1vw;
    }
    .Question {
        border: none;
    }
    /* Home, Hint, Submit, and Skip buttons*/
    .TemplateLayoutRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 2%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .FeedbackRow {
        margin-top: 2%;
        text-align: center;
        justify-content: center;
    }
    .container {
        display: flex;
        justify-content: center;
    }
    .item {
        align-self: center;
    }
    .menu-dropdown {
        width: 75%;
        margin: auto;
        text-align-last: center;
        font-size: 25px;
    }
    /* changes the svg images display features*/
    .CompoundImage {
        padding: 5%;
        border: solid black;
        border-width: 2px;
    }
    /* changes the svg images display features AFTER clicked and held down */
    .CompoundImage:active {
        background-color: #ffffff;
        position: relative;
        display: block;
        top: -10px;
        left: -200px;
        z-index: 99;
        transform: scale(2.5); 
        transition: 300ms ease;
    }
    .RankWidth {
        width: 80px;
    }
    .SubmitButton {
        font-family: "Open Sans";
        margin-left: 10px;
        margin-right: 10px;
    }
    .SkipButton {
        font-family: "Open Sans";
        margin-left: 10px;
        margin-right: 10px;
    }
    .CorrectSolutionCard {
        width: 950px;
        max-height: 800px;
        overflow-y: auto;
        margin-left: -15%;
        margin-top: -650px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 22px;
        position: absolute;
        z-index: 1;
    }
    .IncorrectSolutionCard {
        width: 800px;
        max-height: 800px;
        overflow-y: auto;
        margin-left: -15%;
        margin-top: -650px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 22px;
        position: absolute;
        z-index: 1;
    }
    .InvalidRankingCard {
        width: 800px;
        max-height: 800px;
        overflow-y: auto;
        margin-left: -15%;
        margin-top: -500px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 22px;
        position: absolute;
        z-index: 1;
        text-align: center;
    }
    .HintCard {
        margin-left: -75%;
        margin-top: -600px;
        align-items: center;
        z-index: 1;
        width: 800px;
        max-height: 600px;
        overflow-y: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
        background-color: #FCFCFC;
        border: solid black;
        border-width: 2px;
        border-radius: 10px;
        font-family: "Open Sans";
        font-size: 15px;
        text-align: left;
    }
    .HintCategory {
        margin-bottom: 10%;
    }
    .HintGlossaryDiv {
        padding: 50px;
    }
    .CyclohexaneHintImage {
        padding: 15px;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .fa-info-circle {
        margin-left: 2%;
        font-size: 25px;
        color: blue;
    }
    .fa-info-circle:hover {
        cursor: pointer;
    }
    .fa-times {
        margin-left: 95%;
        font-size: 30px;
        color: red;
    }
    .fa-times:hover {
        cursor: pointer;
    }
    .SolutionImage {
        width: 100%;
        height: 140px;
    }
    .NextQuestionButton {
        width: 18%;
        height: 50px;
        margin-top: 1.5%;
        background-color: #4040FF;
        border: solid #4040FF;
        font-family: "Open Sans";
        font-size: 12px;
    }
    .fa-home {
        font-size: 60px;
        padding-top: 7px;
    }
    .fa-home:hover {
        cursor: pointer;
    }
    .footer {
        background-color: white;
        color: black;
        padding-top: 5px;
        height: 25px;
        align-content: center;
    }
    quick-link {
        font-family: 'Open Sans';
        font-size: 15px;
        font-weight: 600;
        color: black;
    }
    quick-link:hover {
        text-decoration: black underline;
    }
    hint-link {
        font-family: 'Open Sans';
        font-size: 20px;
        font-weight: 600;
        color: blue;
        margin-left: 10%;
    }
    hint-link:hover {
        text-decoration: underline;
    }
    solution-text {
        font-size: 15px;
    }
    feedback-text {
        font-size: 20px;
    }
    .AuthorCard .card-title {
        font-family: 'Open Sans';
        font-weight: 900;
        font-size: 30px;
        text-align: center;
        margin-top: 2%;
        color: black;
    }
    .AuthorCard .card-body {
        font-family: 'Open Sans';
        font-size: 20px;
        text-align: center;
    }
    h1 {
        margin-top: 35%;
        text-align: center;
        font-size: 60px;
        font-family: "Open Sans"
    }
    h2 {
        text-align: center;
        font-size: 25px;
        font-family: "Open Sans"
    }
    h3 {
        text-align: center;
        color: red;
        font-size: 30px;
        font-weight: 900;
        font-family: "Open Sans"
    }
    h5 {
        text-align: center;
        color: green;
        font-size: 30px;
        font-weight: 900;
        font-family: "Open Sans"
    }
    h6 {
        text-align: center;
        color: black;
        font-size: 20px;
        font-weight: bold;
        font-family: "Open Sans"
    }
    .SolutionActionBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .HomeButton {
        margin-left: 10px;
        margin-right: 10px;
    }
    .HintButton {
        font-family: "Open Sans";
        color: #000 !important;
    }
    .ImageGridContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.ActionButton {
    margin: 1%;
    height: 80%;
    font-size: 100%;
    color: black;
    font-weight: bold;
    font-family: "Open Sans"
}